.wrap{
  margin: 0 auto;
  width: 80%;
}


.services-container {
    text-align: center;
    padding: 40px;
    
  }


  .home-container {
    text-align: center;
    padding: 40px;
    margin-top: 30px;
  }

  .home-grid {
    display: flex;
    justify-content: center;
    gap: 30px;
  }

  .home-grid p{
    margin-top: 15px;
  }

  .home-item {
    padding: 10px;
    /* border-radius: 8px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    width: 240px;
    transition: transform 0.3s ease;
  }

  .home-title {
    font-size: 16px;
    font-weight: 600;
    color: #b22222;
  }

  .home-icon {
    font-size: 40px;
    color: #b22222;
    margin-bottom: 10px;
  }
  
  .services-subtitle {
    font-size: 14px;
    color: #b22222;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .view-all-button {
    padding: 10px 20px;
    background-color: #b22222;
    color: #ffffff;
    font-size: 14px;
    border: solid 1px transparent;
  }
  
  .view-all-button:hover {
    background-color: #fff;
    border: solid 1px #b22222;
    color: #b22222;
    transition: background-color 0.6s ease;
  }
  .services-title {
    font-size: 28px;
    font-weight: bold;
    color: #333;
    margin: 10px 0 40px;
  }
  
  .services-grid {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .service-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 180px;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .service-item:hover {
    transform: translateY(-5px);
    color: #f0a500;
  }
  
  .service-icon {
    font-size: 40px;
    color: #b22222;
    margin-bottom: 10px;
  }
  
  .service-title {
    font-size: 16px;
    font-weight: 600;
    color: #b22222;
  }

  /* .services-title{
    color: #fc5521;
  } */
  
  .service-style {
    background-color: #f9f9f9;
  }

.split-section {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;
}

.titext{
  margin: 0 auto;
  /* width: 80%; */
}

.titext h1{
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 50px;
    /* margin-left: 12px; */
}

.titext p{
  width: 400px;
  /* margin-left: 12px; */
    font-size: 2rem;
    margin-bottom: 20px;
    font-weight: bolder;
    color: #fc5521;
}

/* .imager{
  display: grid;
  place-items: center;
  height: 100vh;
} */

.ensection {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  /* padding: 2rem; */
  max-width: 900px;
  margin-top: 10px;
  /* border: solid 1px red; */
}

.enline{
  border: solid 1px #464545;
  width: 10%;
  /* margin: 0 auto; */
  margin-bottom: 16px;
}

.caveat{
  font-size: 2.5rem;
  font-weight:bolder;
  color: #b22222;
  margin: 20px 0 20px;;
}

.text-content {
  max-width: 400px;
  flex: 1;
  padding-right: 2rem;
  padding-left: 2rem;
}

.text-content h1 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.text-content p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.text-content button {
  padding: 0.8rem 2rem;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.entext {
  max-width: 400px;
  flex: 1;
  /* border: solid 1px red; */
  /* padding-right: 2rem; */
  /* padding-left: 2rem; */
}

.entext h1 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.entext p {
  font-size: 1rem;
  line-height: 1.8;
  /* margin-bottom: 2rem; */
  /* border: solid 1px red; */
}

.entext-two{
  max-width: 400px;
  /* margin-bottom: 18px; */
}

.entext-two h1{
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.entext-two p {
  font-size: 1rem;
  line-height: 1.8;
  /* margin-bottom: 5rem; */
  /* border: solid 1px red; */
  /* margin: 0 auto; */
}

.text-content button:hover {
  background-color: #0056b3;
}

.image-section {
  flex: 1;
  /* text-align: center; */
}

.image-sect{
  flex: 1;
}

.image-sect img {
  max-width: 100%;
  height: 80vh;
  }

.image-section img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .split-section {
    flex-direction: column;
    text-align: center;
  }

  .text-content {
    padding-right: 0;
    margin-bottom: 2rem;
  }

  .image-section img {
    max-width: 80%;
  }

  .image-sect img{
    max-width: 80%;
  }
}