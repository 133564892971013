.portfolio {
    width: 80%;
    margin: 0 auto;
    /* border:solid 1px red */
  }
  
  .portfolio-title {
    margin-left: 12px;
    font-size: 3rem;
    margin-bottom: 20px;
    font-weight: bolder;
    color: #fc5521;
    /* border: solid 1px red; */
  }

  .pagination-controls{
    margin-top: 50px;
  }

  .portfolio p {
    line-height: 1.7;
    width: 400px;
    margin-bottom: 60px;
    margin-left: 12px;
    /* border: solid 1px red; */
  }

  .welcome-title{
    /* border: solid 1px red; */
    width: 300px;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 50px;
    margin-left: 12px;
    /* text-align: left; */
  }
  
  .project-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* gap: 30px; */
  }
  
  .project-item {
    /* width: calc(30% - 40px); */
    width: 30%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: white;
    transition: transform 0.6s ease;
  }
  
  .project-item img {
    /* width: 5vh; */
    height: 45vh;
  }

  .project-title{
    font-weight: bold;
  }
  
  .project-item h3 {
    font-size: 1.2rem;
    color: #333;
    margin: 10px 0;
    padding: 0 10px;
  }
  
  .project-item:hover {
    transform: scale(1.1);
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .load-more {
    margin-top: 30px;
    padding: 10px 20px;
    background-color: #b22222;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .load-more:hover {
    /* background-color: #f0a500; */
    background-color: #fff;
    border: solid 1px #b22222;
    color: #b22222;
    transition: background-color 0.6s ease;
  }


  
  @media (max-width: 768px) {
    .project-item {
      width: calc(45% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .project-item {
      width: calc(100% - 20px);
    }
  
    .portfolio-title {
      font-size: 1.5rem;
    }
  
    .load-more {
      width: 100%;
      padding: 15px;
      font-size: 1.2rem;
    }
  }
  