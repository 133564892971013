.about {
    background-color: #f0eeee;
    /* padding: 50px; */
    /* display: flex; */
    /* border: solid 1px black; */
    display: flex;
    justify-content: center;
    gap: 80px;
    width: 80%;
    margin: auto;
    line-height: 1.6;
  }

  .wrp{
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #f0eeee;
    /* background-color: #f9f9f9; */
  
  }
  
  .about-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
  }
  
  .about-image img {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 10px;
  }
  
  .about-text {
    color: black;
    max-width: 600px;
    margin-left: 30px;
    font-size: 1.2rem;
    line-height: 1.6;
    text-align: justify;
  }

  .about-home {
    /* border: solid 1px blue; */
    color: black;
    /* max-width: 600px; */
    font-size: 1.2rem;
    line-height: 1.6;
    text-align: justify;
  }
  .bitext{
    margin: 0 auto;
    /* border: solid 1px red; */
  }
  
  .bitext h1{
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 20px;
      margin-top: 50px;
  }
  
  .bitext p{
    width: 400px;
      font-size: 2rem;
      margin-bottom: 20px;
      font-weight: bolder;
      color: #fc5521;
  }

  .about-home p{
    font-size:medium;
  }

  .we{
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .about-wrapper {
      flex-direction: column;
      text-align: center;
    }
  
    .about-text {
      margin-left: 0;
      margin-top: 20px;
    }
  
    .about-image img {
      max-width: 100%;
    }
  }
  